/**
 * Sticky header functionality
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * A collection of helper functions for use throughout the project.
 *
 * @since 1.0.0
 */
import * as helpers from '../global/helpers';

/**
 * Common variables used throughout the project.
 *
 * @since 1.0.0
 */
import { els } from './variables';

/**
 * Tracks the current scroll position on the page.
 *
 * @type {Number}
 */
let currentScrollPoint;

/**
 * The point (in pixels) at which the header class should be toggled.
 *
 * @type {Number}
 */
const headerScrollPoint = 100;

/**
 * Toggle the header scrolled class.
 *
 * @param  {Event} e
 */
function maybeToggleScrolledClass(e) {
	/**
	 * Update the current scroll position.
	 *
	 * @type {Number}
	 */
	currentScrollPoint = ( els.html && els.html.scrollTop ) || els.body.scrollTop;

	/**
	 * Check scroll status and toggle .scrolled accordingly.
	 */
	if ( currentScrollPoint >= headerScrollPoint ) {
		els.header.classList.add( 'scrolled' );
	} else {
		els.header.classList.remove( 'scrolled' );
	}
}

window.addEventListener( 'scroll', maybeToggleScrolledClass );
window.addEventListener( 'load', maybeToggleScrolledClass );
