/**
 * This file uses Colorbox to open image and video links in a lightbox.
 */

/**
 * jQuery
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

/**
 * jQuery Colorbox
 *
 * A customizable lightbox plugin for jQuery.
 *
 * @see https://www.npmjs.com/package/jquery-colorbox
 * @see http://www.jacklmoore.com/colorbox/
 *
 * @since 1.0.0
 */
import slick from 'slick-carousel';

/**
 * A collection of helper functions for use throughout the project.
 *
 * @since 1.0.0
 */
import * as helpers from '../global/helpers';

/**
 * Common variables used throughout the project.
 *
 * @since 1.0.0
 */
import { breakpoints } from './variables';

/**
 * Initializes the logo slider
 *
 * @since  1.0.0
 */
function initLogoSlider() {
	const logos = $('.layer--logo-slider');
	logos.each(function () {
		const slides = $(this).find('.slides');
		if (slides.length > 0) {
			slides.slick({
				slidesToShow: 4,
				slidesToScroll: 1,
				infinite: true,
				autoplay: true,
				autoplaySpeed: 7500,
				arrows: false,
				dots: true,
				pauseOnHover: false,
				centerMode: false,
				centerPadding: '40px',
				variableWidth: false,
				responsive: [{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
					},
				}, {
					breakpoint: 520,
					settings: {
						slidesToShow: 1,
					},
				}],
			});
		}
	});
}

document.addEventListener('DOMContentLoaded', () => {
	initLogoSlider();
});
