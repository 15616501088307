import $ from 'jquery';
import slick from 'slick-carousel';
import hoverintent from 'hoverintent';
//import * as helpers from './helpers';
//import {els, constants, breakpoints} from './variables';

const slickUseTransform = $('body').hasClass('ie') ? false : true;

/**
 * Hero Slideshow
 */
function initHeroSlideshow() {
	const heroes = $('.home-panels');
	heroes.each(function() {
		const slides = $(this).find('.home-panel');
		if (slides.length > 0) {
			slides.slick({
				dots: true,				
				autoplay: true,
				autoplaySpeed: 1000,
				speed: 1000,
				arrows: false,
				draggable: false,
				fade: true,
				pauseOnFocus: false,
				pauseOnHover: false,
				swipe: false,
				touchMove: false,
				useTransform: slickUseTransform,
			});
		}
	});
}

document.addEventListener('DOMContentLoaded', () => {
	initHeroSlideshow();
});
