/**
 * Forms
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Imports jQuery.
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

/**
 * Imports jQuery Uniform.
 *
 * A jQuery plugin to make your form controls look how you want them to.
 *
 * @see https://www.npmjs.com/package/jquery-uniform
 *
 * @since 1.0.0
 */
import uniform from 'jquery-uniform';

/**
 * A collection of helper functions for use throughout the project.
 *
 * @since 1.0.0
 */
import * as helpers from '../global/helpers';

/**
 * Common variables used throughout the project.
 *
 * @since 1.0.0
 */
import { els, breakpoints } from './variables';

/**
 * Run .uniform() on file upload fields and wrap select elements
 * in a parent container for easier styling.
 *
 * @since  1.0.0
 */
function initPrettyForms() {
	/**
	 * Run uniform on upload elements only.
	 */
	if ($( '[type="file"]' ).length) {
		$( '[type="file"]' ).each(
			function(){
				var $this = $( this );

				/**
			 * If .uploader is not the parent, add it.
			 */
				if ( ! $this.parent().hasClass( 'uploader' ) ) {
					var classes = $( this ).attr( 'class' );
					$this.uniform();
					$this.removeClass( classes ).parents( '.uploader' ).addClass( classes );
				}

				/**
			 * Fixes issue on Windows Chrome where 'ginput_container_fileupload' class is not being appended
			 */
				$this.closest( '.ginput_container' ).addClass( 'ginput_container_fileupload' );
			}
		);
	}

	/**
	 * Wrap <div> with the class 'selector' around all <select>
	 * elements that do not have the 'multiple' attribute.
	 */
	if ( $( 'select' ).length ) {
		$( 'select' ).not( '[multiple="multiple"]' ).each(
			function(){
				var $this = $( this );

				/**
			 * If .selector is not the parent, add it.
			 */
				if ( ! $this.parent().hasClass( 'selector' ) ) {
					var classes = $( this ).attr( 'class' );
					$this.wrapAll( '<div class="selector">' );
					$this.removeClass( classes ).parents( '.selector' ).addClass( classes );
				}
			}
		);
	}
};

document.addEventListener( 'DOMContentLoaded', initPrettyForms );
// ! Re-run Uniform functions on gravity forms reloads (without page refresh).
$( document ).on( 'gform_post_render', initPrettyForms );
